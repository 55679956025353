// src/pages/About.js
import React, { useState, useEffect } from 'react';
import { PortableText } from '@portabletext/react';
import { motion } from 'framer-motion';

const About = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch('/api/about');
        if (!response.ok) {
          throw new Error('Failed to fetch about data');
        }
        const data = await response.json();
        setAboutData(data);
      } catch (err) {
        console.error('Error fetching about data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!aboutData) return <div>No data found</div>;

  return (
    <motion.div 
      className="about-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="about-content">
        <div className="about-image-container">
          {aboutData.mainImage && (
            <img 
              src={aboutData.mainImage.asset.url} 
              alt="Profile" 
              className="profile-image"
            />
          )}
        </div>
        <div className="about-text-content">
          <h1>{aboutData.title}</h1>
          <div className="bio-section">
            <PortableText value={aboutData.bio} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;