import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import useDeviceDetect from '../hooks/useDeviceDetect';

const Navbar = () => {
  const { isDark, toggleTheme } = useTheme();
  const { isMobile } = useDeviceDetect();

  if (isMobile) return null;

  return (
    <nav className="navbar">
      <div className="nav-brand">
        <Link to="/">Jeremy Gloger</Link>
      </div>
      <div className="nav-right">
        <ul>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/fun-stuff">Fun Stuff</Link></li>
        </ul>
        <button 
          className="theme-toggle" 
          onClick={toggleTheme}
          aria-label="Toggle theme"
        >
          {isDark ? '☀️' : '🌙'}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
