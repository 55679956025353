import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSanityData } from '../hooks/useSanityData';
import { PortableText } from '@portabletext/react';

const BlogPost = () => {
  const { data: posts, loading, error } = useSanityData();
  const { slug } = useParams();
  const navigate = useNavigate();

  const post = posts?.find(p => p.slug.current === slug);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!post) return <div>Post not found</div>;

  // Custom components for PortableText
  const components = {
    block: {
      // Handling different block styles
      normal: ({children}) => <p className="mb-4">{children}</p>,
      h1: ({children}) => <h1 className="text-2xl font-bold mb-4">{children}</h1>,
      h2: ({children}) => <h2 className="text-xl font-bold mb-3">{children}</h2>,
      h3: ({children}) => <h3 className="text-lg font-bold mb-3">{children}</h3>,
    },
    marks: {
      // Handle links that are in the markdown-style format [text][ref]
      link: ({children, value}) => {
        // Find the actual URL from the reference links at the bottom
        const linkText = children[0];
        const linkPattern = new RegExp(`\\[${value}\\]:\\s*(\\S+)`);
        const bodyText = post.body
          .map(block => 
            block.children
              ?.map(child => child.text)
              .join('') || ''
          )
          .join('\n');
        
        const match = bodyText.match(linkPattern);
        const url = match ? match[1] : '#';
        
        return (
          <a 
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {linkText}
          </a>
        );
      }
    }
  };

  return (
    <div className="blog-post-page">
      <button onClick={() => navigate('/blog')} className="back-button">
        ← Back to Blog
      </button>
      <article className="blog-post-full">
        <h1>{post.title}</h1>
        <time>{new Date(post.publishedAt).toLocaleDateString()}</time>
        <div className="blog-content">
          <PortableText 
            value={post.body.filter(block => !block.children?.[0]?.text?.startsWith('['))} 
            components={components}
          />
        </div>
      </article>
    </div>
  );
};

export default BlogPost; 