export const getCurrentWord = async () => {
  try {
    const response = await fetch('/api/word');
    if (!response.ok) {
      throw new Error('Failed to fetch word');
    }
    const data = await response.json();
    console.log('Fetched word:', data.word);
    return data.word.toUpperCase();
  } catch (error) {
    console.error('Error fetching word:', error);
    throw error;
  }
}; 