import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import './Wordle.css';
import { getCurrentWord } from '../../../services/wordService';

const WORD_LENGTH = 5;
const MAX_GUESSES = 6;
const KEYBOARD_ROWS = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
  ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
  ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '⌫']
];

const BACKUP_WORDS = ['REACT', 'CODES', 'BUILD', 'LEARN', 'TEACH'];

const getStats = () => {
  const stats = localStorage.getItem('wordleStats');
  if (stats) {
    return JSON.parse(stats);
  }
  return {
    gamesPlayed: 0,
    gamesWon: 0,
    currentStreak: 0,
    maxStreak: 0,
    lastPlayedDate: null,
    lastGameState: null
  };
};

const generateEmptyGrid = () => {
  return Array(MAX_GUESSES).fill('');
};

const Wordle = () => {
  const [solution, setSolution] = useState('');
  const [grid, setGrid] = useState(generateEmptyGrid());
  const [guesses, setGuesses] = useState(Array(MAX_GUESSES).fill(''));
  const [currentGuess, setCurrentGuess] = useState('');
  const [currentRow, setCurrentRow] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('');
  const [isWinner, setIsWinner] = useState(false);
  const [stats, setStats] = useState(getStats);
  const [showStats, setShowStats] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [hasPlayedToday, setHasPlayedToday] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const today = new Date().toLocaleDateString();
    const savedStats = getStats();
    
    if (savedStats.lastPlayedDate === today) {
      setHasPlayedToday(true);
      if (savedStats.lastGameState) {
        setSolution(savedStats.lastGameState.solution);
        setGuesses(savedStats.lastGameState.guesses);
        setCurrentRow(savedStats.lastGameState.currentRow + 1);
        setGameOver(savedStats.lastGameState.gameOver);
        setIsWinner(savedStats.lastGameState.isWinner);
        setShowConfetti(false);
        if (savedStats.lastGameState.isWinner) {
          setMessage('Congratulations!');
        } else if (savedStats.lastGameState.gameOver) {
          setMessage(`Game Over! The word was ${savedStats.lastGameState.solution}`);
        }
      }
    } else {
      setGuesses(Array(MAX_GUESSES).fill(''));
      setCurrentRow(0);
      setGameOver(false);
      setIsWinner(false);
      setShowConfetti(false);
      setMessage('');
      fetchDailyWord();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchDailyWord = async () => {
    try {
      // Get today's date in the format MM/DD/YYYY
      const today = new Date().toLocaleDateString();
      const storedDate = localStorage.getItem('wordleWordDate');
      const storedWord = localStorage.getItem('wordleCurrentWord');
      
      // Always fetch current word from database to compare
      const databaseWord = await getCurrentWord();
      
      // Update if: date changed, no stored word exists, or words don't match
      if (today !== storedDate || !storedWord || storedWord !== databaseWord) {
        setSolution(databaseWord);
        // Update localStorage with new word and date
        localStorage.setItem('wordleCurrentWord', databaseWord);
        localStorage.setItem('wordleWordDate', today);
      } else {
        setSolution(storedWord);
      }

    } catch (error) {
      console.error('Error fetching word:', error);
      // Use backup word if API fails
      const date = new Date();
      const seed = date.getFullYear() * 10000 + 
                  (date.getMonth() + 1) * 100 + 
                  date.getDate();
      const today = date.toLocaleDateString();
      
      const backupWord = BACKUP_WORDS[seed % BACKUP_WORDS.length];
      setSolution(backupWord);
      localStorage.setItem('wordleCurrentWord', backupWord);
      localStorage.setItem('wordleWordDate', today);
    }
  };

  const isValidWord = async (word) => {
    try {
      const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word.toLowerCase()}`);
      if (!response.ok) return false;
      
      const data = await response.json();
      return Array.isArray(data) && data.length > 0;
    } catch (error) {
      console.error('Error checking word validity:', error);
      return false; // Reject word if API check fails
    }
  };

  const handleKeyPress = async (key) => {
    if (gameOver || hasPlayedToday) return;

    if (key === 'ENTER') {
      if (currentGuess.length !== WORD_LENGTH) {
        setMessage('Word must be 5 letters');
        return;
      }

      // Check if it's a valid word
      const isValid = await isValidWord(currentGuess);
      if (!isValid) {
        setMessage('Not a valid word');
        return;
      }

      submitGuess();
    } else if (key === '⌫') {
      setCurrentGuess(prev => prev.slice(0, -1));
      setMessage('');
    } else if (currentGuess.length < WORD_LENGTH) {
      setCurrentGuess(prev => (prev + key).toUpperCase());
      setMessage('');
    }
  };

  // Update the keyboard event handler to handle async function
  useEffect(() => {
    const handleKeyDown = async (e) => {
      if (gameOver || hasPlayedToday) return;

      if (e.key === 'Enter') {
        await handleKeyPress('ENTER');
      } else if (e.key === 'Backspace') {
        handleKeyPress('⌫');
      } else if (e.key.match(/^[a-zA-Z]$/)) {
        handleKeyPress(e.key.toUpperCase());
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentGuess, currentRow, gameOver, solution, hasPlayedToday]);

  const submitGuess = async () => {
    if (currentGuess.length !== WORD_LENGTH) {
      setMessage('Word must be 5 letters');
      return;
    }

    // Validate word before submitting
    const isValid = await isValidWord(currentGuess);
    if (!isValid) {
      setMessage('Not a valid word');
      return;
    }

    const newGuesses = [...guesses];
    newGuesses[currentRow] = currentGuess;
    setGuesses(newGuesses);

    if (currentGuess === solution) {
      setIsWinner(true);
      setGameOver(true);
      setMessage('Congratulations!');
      setShowConfetti(true);
      updateStats(true, newGuesses, currentRow);
      return;
    }

    if (currentRow === MAX_GUESSES - 1) {
      setGameOver(true);
      setMessage(`Game Over! The word was ${solution}`);
      updateStats(false, newGuesses, currentRow);
      return;
    }

    setCurrentRow(prev => prev + 1);
    setCurrentGuess('');
  };

  // Get keyboard key status for coloring
  const getKeyStatus = (key) => {
    let status = '';
    
    // Check all guesses up to the current row
    for (let i = 0; i <= currentRow; i++) {
      const guess = guesses[i];
      if (!guess) continue;
      
      const guessArray = [...guess];
      const solutionArray = [...solution];

      // First check for exact matches (green)
      if (guessArray.some((letter, index) => letter === key && letter === solutionArray[index])) {
        return 'correct';  // Return immediately if we find a correct position
      }

      // Then check for present but wrong position (yellow)
      if (guessArray.some(letter => letter === key && solutionArray.includes(letter))) {
        status = 'present';
        continue;  // Continue checking other rows in case we find a correct position
      }

      // Finally check for absent letters (grey)
      if (guessArray.includes(key) && !solutionArray.includes(key)) {
        status = 'absent';
      }
    }
    
    return status;
  };

  const getCellClassName = (rowIndex, letter, position) => {
    if (!letter) return '';
    if (solution[position] === letter) return 'correct';
    if (solution.includes(letter)) return 'present';
    return 'absent';
  };

  const updateStats = (won, finalGuesses, finalRow) => {
    const today = new Date().toLocaleDateString();
    
    if (stats.lastPlayedDate === today) return;

    const newStats = {
      ...stats,
      gamesPlayed: stats.gamesPlayed + 1,
      gamesWon: stats.gamesWon + (won ? 1 : 0),
      lastPlayedDate: today,
      lastGameState: {
        guesses: finalGuesses,
        currentRow: finalRow,
        gameOver: true,
        isWinner: won,
        solution: solution,
        message: won ? 'Congratulations!' : `Game Over! The word was ${solution}`
      }
    };

    if (won) {
      if (stats.lastPlayedDate === null || 
          isConsecutiveDay(stats.lastPlayedDate, today)) {
        newStats.currentStreak = stats.currentStreak + 1;
        newStats.maxStreak = Math.max(newStats.currentStreak, stats.maxStreak);
      } else {
        newStats.currentStreak = 1;
      }
    } else {
      newStats.currentStreak = 0;
    }

    setStats(newStats);
    localStorage.setItem('wordleStats', JSON.stringify(newStats));
    setHasPlayedToday(true);
  };

  const isConsecutiveDay = (lastPlayed, today) => {
    const last = new Date(lastPlayed);
    const current = new Date(today);
    const diffTime = Math.abs(current - last);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 1;
  };

  return (
    <div className="wordle-container">
      {showConfetti && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}
      
      <div className="game-header">
        <div className="title">Guess That Word!</div>
        <div className="stats-summary" onClick={() => setShowStats(true)}>
          <div className="stats-preview">
            <span className="streak-icon">🔥</span>
            <span className="streak-count">{stats.currentStreak}</span>
          </div>
        </div>
      </div>

      <div className="wordle-grid">
        {Array(MAX_GUESSES).fill().map((_, i) => (
          <div key={i} className="wordle-row">
            {Array(WORD_LENGTH).fill().map((_, j) => {
              const letter = i === currentRow ? currentGuess[j] : guesses[i]?.[j];
              const cellStatus = i < currentRow || (gameOver && i === currentRow)
                ? getCellClassName(i, letter, j)
                : '';
              return (
                <div 
                  key={j} 
                  className={`wordle-cell ${cellStatus}`}
                >
                  {letter || '\u00A0'}
                </div>
              );
            })}
          </div>
        ))}
      </div>

      {message && <div className="wordle-message">{message}</div>}

      {showStats && (
        <div className="stats-overlay" onClick={() => setShowStats(false)}>
          <div className="stats-modal" onClick={e => e.stopPropagation()}>
            <h2>Statistics</h2>
            <div className="stats-grid">
              <div className="stat-item">
                <div className="stat-number">{stats.gamesPlayed}</div>
                <div className="stat-label">Played</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">
                  {Math.round((stats.gamesWon / stats.gamesPlayed) * 100) || 0}%
                </div>
                <div className="stat-label">Win Rate</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">{stats.currentStreak}</div>
                <div className="stat-label">Current Streak</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">{stats.maxStreak}</div>
                <div className="stat-label">Max Streak</div>
              </div>
            </div>
            <button className="close-stats" onClick={() => setShowStats(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      <div className="keyboard" style={{ opacity: hasPlayedToday ? 0.5 : 1 }}>
        {KEYBOARD_ROWS.map((row, i) => (
          <div key={i} className="keyboard-row">
            {row.map((key) => {
              const status = getKeyStatus(key);
              return (
                <button
                  key={key}
                  className={`keyboard-key ${status} ${
                    key === 'ENTER' ? 'enter-key' : key === '⌫' ? 'backspace-key' : ''
                  }`}
                  onClick={() => handleKeyPress(key)}
                  disabled={hasPlayedToday}
                >
                  {key}
                </button>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

const getLetterStatus = (letter, position, solution) => {
  if (!letter) return '';
  if (solution[position] === letter) return 'correct';
  if (solution.includes(letter)) return 'present';
  return 'absent';
};

export default Wordle; 