import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { AiOutlineHome, AiOutlineRead, AiOutlineUser, AiOutlineMail, AiOutlineStar } from 'react-icons/ai';
import { BsSun, BsMoon } from 'react-icons/bs';

const MobileNav = () => {
  const { isDark, toggleTheme } = useTheme();
  const location = useLocation();

  return (
    <nav className="mobile-nav">
      <Link to="/" className={`mobile-nav-link ${location.pathname === '/' ? 'active' : ''}`}>
        <AiOutlineHome className="mobile-nav-icon" />
        <span>Home</span>
      </Link>
      <Link to="/blog" className={`mobile-nav-link ${location.pathname === '/blog' ? 'active' : ''}`}>
        <AiOutlineRead className="mobile-nav-icon" />
        <span>Blog</span>
      </Link>
      <Link to="/about" className={`mobile-nav-link ${location.pathname === '/about' ? 'active' : ''}`}>
        <AiOutlineUser className="mobile-nav-icon" />
        <span>About</span>
      </Link>
      <Link to="/fun-stuff" className={`mobile-nav-link ${location.pathname === '/fun-stuff' ? 'active' : ''}`}>
        <AiOutlineStar className="mobile-nav-icon" />
        <span>Fun</span>
      </Link>
      <Link to="/contact" className={`mobile-nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>
        <AiOutlineMail className="mobile-nav-icon" />
        <span>Contact</span>
      </Link>
      <button onClick={toggleTheme} className="mobile-nav-link theme-toggle">
        {isDark ? <BsSun className="mobile-nav-icon" /> : <BsMoon className="mobile-nav-icon" />}
        <span>Theme</span>
      </button>
    </nav>
  );
};

export default MobileNav; 