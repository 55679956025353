import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Wordle from './FunStuff/MiniGames/Wordle';
import './FunStuff.css';

const FunStuff = () => {
  const [activeTab, setActiveTab] = useState('games');

  return (
    <div className="fun-stuff">
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'games' ? 'active' : ''}`}
          onClick={() => setActiveTab('games')}
        >
          Games
        </button>
        <button
          className={`tab ${activeTab === 'coming-soon' ? 'active' : ''}`}
          onClick={() => setActiveTab('coming-soon')}
        >
          Coming Soon
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'games' && <Wordle />}
        {activeTab === 'coming-soon' && (
          <div style={{ color: 'var(--text-color)' }}>More fun stuff coming soon!</div>
        )}
      </div>
    </div>
  );
};

export default FunStuff;
