import React from 'react';
import { useSanityData } from '../hooks/useSanityData';
import { Link } from 'react-router-dom';

const Home = () => {
  const { data: posts, loading, error } = useSanityData();

  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Get the 3 most recent posts
  const recentPosts = posts?.slice(0, 3);
  console.log('Recent posts:', recentPosts);

  return (
    <div className="home-container">
      <h1>Welcome to My Blog</h1>
      <p className="home-intro">Check out my latest posts below</p>
      <div className="blog-grid">
        {recentPosts?.map(post => {
        
          return (
            <article key={post._id} className="blog-preview">
              <h2>{post.title}</h2>
              <time>{new Date(post.publishedAt).toLocaleDateString()}</time>
              {post.author && <p className="author">By {post.author}</p>}
              {post.preview ? (
                <p className="preview-text">{post.preview}</p>
              ) : (
                <p className="preview-text">No preview available</p>
              )}
              <Link to={`/blog/${post.slug.current}`} className="read-more">
                Read More →
              </Link>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default Home;
