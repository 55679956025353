import React from 'react';
import { useSanityData } from '../hooks/useSanityData';
import { Link } from 'react-router-dom';

const BlogList = ({ posts }) => {
  return (
    <div className="blog-list">
      {posts.map(post => (
        <article key={post._id} className="blog-preview">
          <h2>{post.title}</h2>
          <time>{new Date(post.publishedAt).toLocaleDateString()}</time>
          {post.author && <p className="author">By {post.author}</p>}
          {post.preview ? (
            <p className="preview-text">{post.preview}</p>
          ) : (
            <p className="preview-text">No preview available</p>
          )}
          <Link to={`/blog/${post.slug.current}`} className="read-more">
            Read More →
          </Link>
        </article>
      ))}
    </div>
  );
};

const Blog = () => {
  const { data: posts, loading, error } = useSanityData();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="blog-list-container">
      <h1>All Blog Posts</h1>
      <div className="blog-list">
        {posts?.map(post => (
          <article key={post._id} className="blog-preview">
            <h2>{post.title}</h2>
            <time>{new Date(post.publishedAt).toLocaleDateString()}</time>
            {post.author && <p className="author">By {post.author}</p>}
            {post.preview ? (
              <p className="preview-text">{post.preview}</p>
            ) : (
              <p className="preview-text">No preview available</p>
            )}
            <Link to={`/blog/${post.slug.current}`} className="read-more">
              Read More →
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
};

export default Blog; 